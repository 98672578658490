import React, {useState} from "react"

import SectionHeader from "./section-header"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Reveal , Animation} from "react-genie"

const divider = 
  <div style={{marginBottom:"4rem"}}>
    <hr style={{margin:"0 auto"}}></hr>
  </div>
;

const QuestionItem = (props) => {
  const [qustionOpen, setQuestionOpen] = useState(false);
  return (
    <div className="main-qa-content" style={{marginBottom:"2rem", background:"#f2f2f2", border:"1px solid #555", borderRadius:"0.5rem"}}>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", padding:"1rem", cursor:"pointer"}} onClick={() => setQuestionOpen(!qustionOpen)}>
        <h3 style={{margin:0, wordBreak:"break-all", textAlign:"left"}}>
        <FontAwesomeIcon icon={faQuestionCircle} style={{fontSize:"2rem", marginRight:15}}/>{props.question}
        </h3>
        <div style={{position:"relative"}}>
          <span style={{position:"absolute", top:"50%", right:"50%", transform:"translate(-50%, -50%)"}}>{qustionOpen ? "-" : "+"}</span>
        </div>
      </div>
      {
        qustionOpen ?
        <div style={{display:"inline-block", width:"100%", padding:"1rem", borderTop:"1px solid #555"}}>
          <h3 style={{margin:0, textAlign:"left", wordBreak:"break-all"}}>
            {props.answer}
          </h3>
          <p style={{margin:0, textAlign:"left"}}>{props.children}</p>
        </div>
        : null
      }
    </div>
  )
};

const MainQA = (props) => (
  <div className="main-qa" style={{ 
      padding: "4rem 0 0 0",
      // backgroundColor: "#f2f2f2",
      textAlign:"center",
      // marginBottom:"-2rem"
    }}>
    <div id="main-qa-register-item-master" className="main-qa-container">
      <QuestionItem question="無料で使えますか？" answer="はい。無料版もございます。">
        有料版より機能は制限されます。
      </QuestionItem>
      <QuestionItem question="どんなスマホでも使えますか？" answer="今はiPhone、iPadのみで使えます。">
        Android版は計画中です。
      </QuestionItem>
      <QuestionItem question="どれくらい棚卸が楽になりますか？" answer="某社様は、検算を含めると、かかる時間は半分以下（約65%削減）、労力は三分の一になったそうです。">
        業種や規模によって差はありますが、逆に大変になった、というお話がお客様からきたことはありません。
      </QuestionItem>
      <QuestionItem question="商品はいくつくらい登録できますか？" answer="アイテム数の制限はありません。">
        お店の商品すべてご登録いただけます。
      </QuestionItem>
      <QuestionItem question="オリジナルの商品にはバーコードがないものもあるのですが……" answer="バーコードがなくても大丈夫です。">
        バーコードスキャンをスキップする機能があります。
      </QuestionItem>
      <QuestionItem question="アイテム数が多いので、棚卸は皆でやっています。" answer="スマホごとに棚卸をしていただき、最後にデータを合計することができます。">
        1台ごとに料金はかかりますが、スマホは何台でも連携できます。
      </QuestionItem>
      <QuestionItem question="アプリを使えない業種はありますか？" answer="ありません。">
        お使いいただいているお客様は、卸売、小売、飲食、ネット販売等、業態も業種も規模もさまざまです。
      </QuestionItem>
      <QuestionItem question="内部管理用にバーコードを作りたいのですが、そういう機能はありますか？" answer="現在はありませんが計画中です。">
      </QuestionItem>
      <QuestionItem question="商品情報を検索することはできますか？" answer="ご登録いただいた商品でしたら検索できます。">
      </QuestionItem>
      <QuestionItem question="商品情報の管理で写真も一緒に保存したいです。" answer="貴重なご意見ありがとうございます。検討いたします。">
      </QuestionItem>
      <QuestionItem question="棚卸だけでなく、レジと連動できるようになりませんか？" answer="貴重なご意見ありがとうございます。検討いたします。">
      </QuestionItem>
      <QuestionItem question="電話とLINEと写真くらいしかスマホを使っていないような私にも使えますか？" answer="大丈夫です。">
        動画などでわかりやすい説明を心がけております。わからない場合はお問い合わせください。
      </QuestionItem>
      <QuestionItem question="市販のバーコードリーダーと連動できますか？" answer="Bluetooth接続するバーコードリーダーと連動可能です。">
      Inateck BCST-70、RIOTEC iDC9277Aの2機種で動作を確認できております。<br/>
      ※この2機種以外での動作は保証できません。<br/><br/>
      RIOTEC iDC9277Aは、弊社で販売も行っております。<br/>
      <a href="https://forms.gle/9AzsHoN9UfSBoqQDA">RIOTEC iDC9277A 購入フォーム</a>
      </QuestionItem>
    </div>  
  </div>
)

export default MainQA
