import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import HeroQA from "../components/hero-qa";
import MainQA from "../components/main-qa";

import {RevealGlobalStyles} from "react-genie";

const QA = () => (
  <Layout>
    <RevealGlobalStyles />
    <SEO />
    <Header siteTitle="バーコードde実棚 | よくある質問"/>
    <HeroQA />
    <MainQA />
  </Layout>
)

export default QA
